import * as React from "react"

// Utilities
import { GetSizePickerVariant } from "../utils/product/getSizePickerVariant"

// Components
import { Button, ButtonProps } from "../Button"
import { UiButtonOwnProps } from "../ui/Button"

export interface ProductButtonOwnProps {
  hasNotifyMe: boolean
  isInDrawer: boolean
  isProductSoldOut: boolean
  isProductOutOfStock: boolean
  isProductRelated: boolean
  isProductSingleSize: boolean
  isVariantSelected: boolean
  isVariantOutOfStock: boolean
  isVariantSoldOut: boolean
  sizePickerVariant: GetSizePickerVariant
  isGiftcard: boolean
  giftcardValue?: string
  giftcardHasError?: boolean
  isMobile: boolean
  isLoading?: boolean
}

const getVariant = ({
  isProductOutOfStock,
  isVariantOutOfStock,
  isLoading,
  hasNotifyMe,
}: Pick<
  ProductButtonOwnProps,
  "isProductOutOfStock" | "isVariantOutOfStock" | "hasNotifyMe" | "isLoading"
>): UiButtonOwnProps["variant"] => {
  if (
    !isLoading &&
    ((hasNotifyMe && isVariantOutOfStock) || isProductOutOfStock)
  ) {
    return "ghost"
  }
  return "primary"
}

const getDisabled = ({
  isProductSoldOut,
  isVariantSoldOut,
  isLoading,
  isProductRelated,
}: Pick<
  ProductButtonOwnProps,
  "isProductSoldOut" | "isVariantSoldOut" | "isLoading" | "isProductRelated"
>): boolean => {
  if (
    (isVariantSoldOut && !isProductRelated) ||
    (isProductSoldOut && !isProductRelated) ||
    isLoading
  ) {
    return true
  }
  return false
}

const getIsVisuallyDisabled = ({
  isProductSoldOut,
  isProductOutOfStock,
  isProductRelated,
  isVariantSelected,
  isVariantSoldOut,
  isInDrawer,
  isMobile,
  isGiftcard,
  isLoading,
  giftcardValue,
  giftcardHasError,
  sizePickerVariant,
}: Omit<
  ProductButtonOwnProps,
  "hasNotifyMe" | "isProductSingleSize" | "isVariantOutOfStock"
>): boolean => {
  if (!isVariantSelected && !isProductOutOfStock) {
    if (
      isProductSoldOut ||
      isInDrawer ||
      giftcardHasError ||
      (isMobile && isGiftcard && giftcardValue === "") ||
      (isMobile && sizePickerVariant === "inline-list" && !isProductRelated) ||
      (isMobile &&
        sizePickerVariant === "named-inline-list" &&
        !isProductRelated) ||
      (isMobile &&
        sizePickerVariant === "wrapped-inline-list" &&
        !isProductRelated) ||
      (!isMobile &&
        (sizePickerVariant === "inline-list" ||
          sizePickerVariant === "named-list" ||
          (sizePickerVariant === "named-inline-list" && !isProductRelated) ||
          sizePickerVariant === "wrapped-inline-list"))
    ) {
      return true
    }
  }
  if (
    (isVariantSoldOut && !isProductRelated) ||
    (isProductSoldOut && !isProductRelated) ||
    isLoading
  ) {
    return true
  }
  return false
}

const getHoverLabel = ({
  isProductSoldOut,
  isProductRelated,
  isVariantSelected,
  isGiftcard,
  giftcardValue,
  isInDrawer,
}: Pick<
  ProductButtonOwnProps,
  | "isProductSoldOut"
  | "isProductRelated"
  | "isVariantSelected"
  | "isGiftcard"
  | "giftcardValue"
  | "isMobile"
  | "isInDrawer"
>): string | boolean => {
  if (!isVariantSelected && !isInDrawer && !isProductRelated && !isGiftcard) {
    if (!isProductSoldOut) {
      return "Select size"
    }
  }
  if (isGiftcard && giftcardValue === "") {
    return "Insert amount"
  }
  return false
}

const getLabel = ({
  isProductSoldOut,
  isProductOutOfStock,
  isProductRelated,
  isProductSingleSize,
  isVariantSelected,
  isVariantOutOfStock,
  isVariantSoldOut,
  isGiftcard,
  hasNotifyMe,
  isInDrawer,
}: Omit<ProductButtonOwnProps, "isMobile" | "sizePickerVariant">): string => {
  if (hasNotifyMe && (isVariantOutOfStock || isProductOutOfStock)) {
    return "Notify me"
  }
  if (isVariantSoldOut || isProductSoldOut) {
    return "Product sold out"
  }
  if (
    isProductRelated &&
    !isVariantSelected &&
    !isProductSingleSize &&
    !isInDrawer
  ) {
    if (isGiftcard) {
      return "Insert amount"
    }
    return "Select size"
  }
  return "Add to cart"
}

// Component
export const ProductButton: React.FC<ButtonProps & ProductButtonOwnProps> = ({
  hasNotifyMe,
  isInDrawer,
  isProductSoldOut,
  isProductOutOfStock,
  isProductSingleSize,
  isProductRelated,
  isVariantSelected,
  isVariantOutOfStock,
  isVariantSoldOut,
  sizePickerVariant,
  isGiftcard,
  giftcardValue,
  giftcardHasError,
  isMobile,
  isLoading,
  ...props
}) => (
  <Button
    {...props}
    variant={getVariant({
      isProductOutOfStock: isProductOutOfStock,
      isVariantOutOfStock: isVariantOutOfStock,
      isLoading: isLoading,
      hasNotifyMe: hasNotifyMe,
    })}
    disabled={getDisabled({
      isProductSoldOut: isProductSoldOut,
      isVariantSoldOut: isVariantSoldOut,
      isLoading: isLoading,
      isProductRelated: isProductRelated,
    })}
    isVisuallyDisabled={getIsVisuallyDisabled({
      isProductSoldOut: isProductSoldOut,
      isProductOutOfStock: isProductOutOfStock,
      isProductRelated: isProductRelated,
      isVariantSelected: isVariantSelected,
      isVariantSoldOut: isVariantSoldOut,
      isInDrawer: isInDrawer,
      isMobile: isMobile,
      isGiftcard: isGiftcard,
      isLoading: isLoading,
      giftcardValue: giftcardValue,
      giftcardHasError: giftcardHasError,
      sizePickerVariant: sizePickerVariant,
    })}
    hoverLabel={getHoverLabel({
      isProductSoldOut: isProductSoldOut,
      isProductRelated: isProductRelated,
      isVariantSelected: isVariantSelected,
      isGiftcard: isGiftcard,
      giftcardValue: giftcardValue,
      isMobile: isMobile,
      isInDrawer: isInDrawer,
    })}
    isLoading={isLoading}
  >
    {getLabel({
      isProductSoldOut: isProductSoldOut,
      isProductOutOfStock: isProductOutOfStock,
      isProductRelated: isProductRelated,
      isProductSingleSize: isProductSingleSize,
      isVariantSelected: isVariantSelected,
      isVariantOutOfStock: isVariantOutOfStock,
      isVariantSoldOut: isVariantSoldOut,
      isGiftcard: isGiftcard,
      isInDrawer: isInDrawer,
      hasNotifyMe: hasNotifyMe,
    })}
  </Button>
)
