import React, { useEffect, useState } from "react"

import ContentModules from "../../components/Page/ContentModules"
import SEO from "../../components/seo"
import Product from "../../components/v2/product"
import { PageProps, navigate } from "gatsby"
import { ProductCategory } from "@medusajs/medusa"
import {
  ContentfulCollectionProduct,
  EnrichedMedusaProduct,
} from "../../../gatsby-node"
import { useAccountContext } from "../../context/AccountContext"

export interface ContentfulSizeGuide {
  title: string
  medusaCollectionHandles: string[]
  medusaProductTypes: string[]
  sizeGuides: {
    gender: string
    id: string
    title: string
    text: {
      childMarkdownRemark: unknown
    }
    body: {
      raw: string
      references: unknown[]
    }
  }[]
}

interface ProductPageTemplateProps {
  category: Pick<ProductCategory, "handle" | "id" | "name">
  collection: Pick<ProductCategory, "handle" | "id" | "name" | "metadata">
  product: EnrichedMedusaProduct
  size_guides: ContentfulSizeGuide[]
  type: string
  subType: string
  products: Pick<
    ContentfulCollectionProduct,
    | "color"
    | "id"
    | "handle"
    | "imagePacks"
    | "thumbnail"
    | "disableNotifyMe"
    | "sku"
    | "title"
    | "isGiftcard"
    | "variants"
  >[]
  shopstoryContent: unknown
}

const ProductPageTemplate = ({
  pageContext,
  location,
}: PageProps<object, ProductPageTemplateProps>) => {
  const { category, collection, product, products, size_guides } = pageContext
  const { loggedIn, isStaff } = useAccountContext()
  const canonicalUrl = `/product/${product.handle}`
  const preSelectedSize = location?.state?.size

  React.useEffect(() => {
    if (loggedIn === undefined || isStaff === undefined) {
      return
    }

    if (category?.handle === "internal-archive" && !loggedIn) {
      navigate("/the-secret-staff-login")
    }

    if (
      (process.env.GATSBY_ARCHIVE_IS_ACTIVE === "false" && !isStaff) ||
      (category?.handle === "internal-archive" && !isStaff)
    ) {
      navigate("/")
    }
  }, [loggedIn, isStaff])

  const jsonLdProduct = {
    title: product.title,
    description: product.description?.description,
    category: category.name,
    thumbnail: product.thumbnail?.gatsbyImageData?.images?.fallback?.src,
    color: product.color?.title,
    sku: product.sku,
  }

  const handleContentModules = () => {
    if (!product.contentModules?.length) {
      return null
    }

    return (
      <div className="content-module">
        <ContentModules
          allModules={product.contentModules}
          shopstoryContent={pageContext.shopstoryContent}
        />
      </div>
    )
  }

  if (category?.handle === "internal-archive" && (!loggedIn || !isStaff)) {
    return null
  }

  return (
    <>
      <SEO
        canonicalUrl={canonicalUrl}
        jsonLdProduct={jsonLdProduct}
        title={product.title}
        description={product.description?.description}
      />
      {product && (
        <Product
          category={category}
          sizeGuideGroups={size_guides}
          selectedColor={product}
          colorOptions={products}
          collection={collection}
          information={product?.information}
          pageContext={pageContext}
          contentModules={handleContentModules()}
          preselectedSize={preSelectedSize}
        />
      )}
    </>
  )
}

export default ProductPageTemplate
