import * as React from "react"

// External packages
import { useProducts, useShippingOptions } from "medusa-react"
import { navigate } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { uniqBy, capitalize } from "lodash"
import { Box, Flex, Heading, Paragraph, Text, Image } from "theme-ui"
import { z } from "zod"

// Contexts
import { useStore } from "../../../context/NewStoreContext"
import { useNotificationContext } from "../../../context/NotificationContext"
import { useInterfaceContext } from "../../../context/InterfaceContext"

// Utilities
import { formatMoneyAmount } from "../../../utils/prices"
import { isMobileWindow } from "../../../utils/render-util"
import {
  sortVariants,
  sizeToInches,
  addToRecentlyViewed,
} from "../../../utils/functions/product-util"
import { getSizingUnit } from "../utils/product/getSizingUnit"
import { isVariantOutOfStock } from "../utils/product/isVariantOutOfStock"
import { isVariantLowInStock } from "../utils/product/isVariantLowInStock"
import { hasKlarna } from "../utils/product/hasKlarna"
import { getTitle } from "../utils/product/getTitle"
import { getSizePickerVariant } from "../utils/product/getSizePickerVariant"
import { getMinimumPrice } from "../utils/product/getMinimumPrice"
import { getMaximumPrice } from "../utils/product/getMaximumPrice"
import { getShoesSize } from "../utils/product/getShoesSize"
import { isProductOutOfStock } from "../utils/product/isProductOutOfStock"
import { getVariantsInventory } from "../utils/product/getVariantsInventory"

// Hooks
import useDrawerHook from "../../../hooks/useDrawerHook"
import useWindowSize from "../../../hooks/useWindowSize"
import { useInView } from "../../../hooks/v2/useInView"
import useLockedBody from "../../../hooks/v2/useLockedBody"

// Services
import Medusa from "../../../services/api"
import {
  trackProductViewed,
  trackSizeSelected,
  trackRestockSignup,
  trackAddToCart,
  trackRelatedProductClicked,
  trackBeddingViewAllSizes,
} from "../../../services/analytics"

// Components
import { ColorPicker, ColorPickerItem } from "../ui/ColorPicker"
import { Drawer } from "../ui/Drawer"
import { Icon } from "../ui/Icon"
import { NumberInput } from "../ui/NumberInput"
import { SizePickerList, SizePickerListRow } from "../ui/SizePickerList"
import { SizePicker, SizePickerItem } from "../ui/SizePicker"
import { Tag } from "../ui/Tag"
import { Checkbox } from "../ui/Checkbox"
import Layout from "../../layouts"
import SizeGuide from "../../ProductPage/SizeGuide"
import { Link } from "../Link"
import { Button } from "../Button"
import { Gallery } from "./Gallery"
import { RelatedProduct } from "./RelatedProduct"
import { ProductsTabs } from "./ProductsTabs"
import { ProductButton } from "./ProductButton"
import { TextInlineButtonField } from "../form/TextInlineButtonField"

// Types
import {
  ContentfulCollectionProduct,
  EnrichedMedusaProduct,
  EnrichedMedusaRelatedProduct,
  EnrichedMedusaVariant,
} from "../../../../gatsby-node"
import {
  isPillow,
  isBedding as isBeddingProduct,
} from "../../../utils/product-types"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import type { ProductCategory, ProductVariant } from "@medusajs/medusa"

// Assets
import KlarnaLogo from "../../../assets/images/logo/klarna.svg"

import { CustomGiftCard, useCreateCustomGiftCard } from "./CustomGiftCard"
import { getPriceLabel } from "../utils/product/getPriceLabel"
import { hasMeasureUnit } from "../utils/product/hasMeasureUnit"
import { isPurchasable } from "../utils/product/isPurchasable"
import { ContentfulSizeGuide } from "../../../templates/medusa/ProductTemplate"
import { useZendesk } from "../../../hooks/v2/useZendesk"
import { getToweLabel } from "../utils/product/getTowelLabel"

interface ProductPageProps {
  colorOptions: Pick<
    ContentfulCollectionProduct,
    | "color"
    | "id"
    | "handle"
    | "imagePacks"
    | "thumbnail"
    | "disableNotifyMe"
    | "sku"
    | "title"
    | "isGiftcard"
    | "variants"
  >[]
  collection: Pick<ProductCategory, "handle" | "id" | "name" | "metadata">
  category: Pick<ProductCategory, "handle" | "id" | "name">
  information: any
  selectedColor: EnrichedMedusaProduct
  pageContext: any
  contentModules: any
  preselectedSize?: string
  sizeGuideGroups?: ContentfulSizeGuide[]
}

export interface ColorPatterns {
  [key: string]: ContentfulCollectionProduct[]
}

export interface SelectedVariant extends PricedVariant {
  quantity: number
  type: string
}

interface ProductPageState {
  selectedVariant: SelectedVariant | undefined
  inventory: any
  product: ContentfulCollectionProduct | undefined
}

const Product = (props: ProductPageProps) => {
  const {
    colorOptions,
    category,
    collection,
    information,
    contentModules,
    preselectedSize,
    sizeGuideGroups,
  } = props

  const { cart, createLineItem, updateCart } = useStore()
  const [selectedColor, setSelectedColor] = React.useState(props.selectedColor)

  const {
    products: MedusaProducts,
    isLoading: MedusaProductLoading,
    isSuccess: MedusaProductsLoaded,
  } = useProducts(
    {
      id: selectedColor?.objectId,
      cart_id: cart?.id,
      region_id: cart?.region_id,
      currency_code: cart?.region?.currency_code,
    },
    {
      enabled: !!cart?.id && !!selectedColor?.objectId,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error)
      },
    }
  )
  const medusaProduct = MedusaProducts?.[0]
  const preSelectedSize = null

  const { pushNotification, dismissNotification } = useNotificationContext()
  const [openedDrawer, setOpenedDrawer] = React.useState("")
  const [isZendeskOpen, setIsZendeskOpen] = React.useState(false)
  const [state, setState] = React.useState<ProductPageState>({
    selectedVariant: preselectedSize
      ? {
          ...preSelectedSize,
        }
      : {
          original_price: 0,
          original_price_incl_tax: 0,
          calculated_price: 0,
          calculated_price_incl_tax: 0,
          original_tax: 0,
          calculated_tax: 0,
          tax_rates: [],
          prices: [],
          title: "",
          quantity: 1,
          inventory_quantity: 0,
        },
    inventory: {},
    product: undefined,
  })
  const [locked, setLocked] = useLockedBody(false)
  const [giftcardValue, setGiftcardValue] = React.useState("")

  const sizePickerVariant = getSizePickerVariant(
    selectedColor?.variants,
    selectedColor?.isGiftcard
  )
  const collectionPath = `/category/${collection?.handle}`
  const [gender, setGender] = React.useState("female")
  const [images, setImages] = React.useState([])
  const [color, setColor] = React.useState(selectedColor.color?.title)
  let { variants, disableNotifyMe } = selectedColor
  const isSleep = selectedColor.sku?.startsWith("SW")
  const isBedding = isBeddingProduct(selectedColor)
  const isTowel = selectedColor?.sku.startsWith("TT-")
  const isSingle = variants?.length < 2
  const isBathMat = selectedColor?.sku?.startsWith("BM-")
  const isDownCollection =
    selectedColor.sku?.startsWith("TD-") || selectedColor.sku?.startsWith("DN-")
  const isNapkin = medusaProduct?.type?.value?.toLowerCase() === "napkin"

  const isShoes =
    selectedColor?.sku?.startsWith("UJ-") ||
    (selectedColor?.sku?.startsWith("NA-") && !isNapkin)

  const shouldShowGiftCardDrawer =
    !selectedColor?.isGiftcard &&
    selectedColor?.sku !== "TT-LS-100x180" &&
    selectedColor?.sku !== "TT-DS-100x180" &&
    selectedColor?.sku !== "TT-IS-100x180" &&
    !selectedColor?.sku.startsWith("DN-HIGH") &&
    !selectedColor?.sku.startsWith("DN-LOW") &&
    !selectedColor?.sku.startsWith("DN-MEDIUM") &&
    !selectedColor?.sku.startsWith("DN-WS") &&
    !selectedColor?.sku.startsWith("DN-AS") &&
    !selectedColor?.sku.startsWith("DN-SS") &&
    !selectedColor?.sku.startsWith("DN-SG")

  const hasNotifyMe = false
  const isProductSoldOut = isProductOutOfStock(state?.inventory) && !hasNotifyMe
  const isVariantSelected = Boolean(state?.selectedVariant?.id)
  const isVariantSoldOut =
    !hasNotifyMe && isVariantOutOfStock(state?.selectedVariant)

  // Custom gift card
  const isGiftcard = Boolean(selectedColor?.isGiftcard)
  const customGiftCardMutation = useCreateCustomGiftCard({
    cartId: cart?.id,
  })
  const [customGiftCardHasError, setCustomGiftCardHasError] =
    React.useState(false)
  const customGiftCardAmountInputRef = React.useRef(null)

  const isMobile = isMobileWindow(useWindowSize()?.width)

  // Notify me states
  const [email, setEmail] = React.useState("")
  const [hasConsent, setHasConsent] = React.useState(false)
  const [consentErrorMessage, setConsentErrorMessage] = React.useState("")
  const [signedUp, setSignedUp] = React.useState([])
  const [newsletterChecked, setNewsletterChecked] = React.useState(false)
  const [isSizeDrawerOpened, setIsSizeDrawerOpened] = React.useState(false)
  const emailSchema = z.string().email()
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()

  const { setWishlistCount, showCart } = useInterfaceContext()

  const { shipping_options } = useShippingOptions(
    {
      region_id: cart?.region_id,
    },
    {
      enabled: !!cart?.id,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error)
      },
    }
  )

  React.useEffect(() => {
    if (!MedusaProductLoading && medusaProduct) {
      const variants = medusaProduct?.variants

      setSelectedColor({
        ...selectedColor,
        variants,
      })
    }
  }, [medusaProduct])

  const estimated = shipping_options
    ?.find((i) => !i.is_return && i.metadata?.transit_time)
    ?.metadata?.transit_time?.toString()

  const colorPatterns: ColorPatterns = uniqBy(colorOptions, "color.id")
    .sort((a, b) => {
      return (
        parseInt(a.color?.metadata?.[`${collection.id}_rank`]) -
        parseInt(b.color?.metadata?.[`${collection.id}_rank`])
      )
    })
    .reduce((acc, next) => {
      const pattern = next.color?.pattern?.toLowerCase()

      if (!pattern) {
        return acc
      }

      if (!acc[pattern]) {
        acc[pattern] = []
      }

      acc[pattern].push(next)

      return acc
    }, {})

  const defaultPatterns = ["solid", "stripes"]

  // Images
  const imagesFemale = selectedColor?.imagePacks?.find(
    (i) => i.isFemale
  )?.images
  const imagesMale = selectedColor?.imagePacks?.find((i) => !i.isFemale)?.images

  const discountValue = cart?.discounts?.[0]?.rule?.value || 0

  // Format price amount
  const formatSelectionPrice = () => {
    if (!cart?.id || !state.product?.id) {
      return ""
    }

    const currency = cart?.region?.currency_code

    const variant = state.product?.variants?.find(
      (i) => i.id === state.selectedVariant?.id
    )

    if (variant?.id) {
      return getPriceLabel(variant, currency, discountValue)
    }

    const variantsIds = medusaProduct?.variants?.map((i) => i.id)

    const variantsFilterd = state.product?.variants?.filter((i) =>
      variantsIds?.includes(i.id)
    )

    const min = getMinimumPrice(variantsFilterd)
    const max = getMaximumPrice(variantsFilterd)
    const priceMin = formatMoneyAmount(
      {
        currencyCode: currency,
        amount: min * ((100 - discountValue) / 100),
      },
      0
    )

    const priceMax = formatMoneyAmount(
      {
        currencyCode: currency,
        amount: max * ((100 - discountValue) / 100),
      },
      0
    )

    return max > min
      ? `${priceMin} - ${priceMax}`
      : getPriceLabel(variantsFilterd?.[0], currency, discountValue)
  }

  // Size picker
  const handleSizePickerClick = (variant) => {
    trackSizeSelected(selectedColor, variant, props.collection)
    setState({
      ...state,
      selectedVariant: {
        ...state.selectedVariant,
        ...variant,
        inventory_quantity: state.inventory[variant.id],
      },
    })
  }

  const handleSelectedVariantReset = () => {
    setState({
      ...state,
      selectedVariant: {
        original_price: 0,
        original_price_incl_tax: 0,
        calculated_price: 0,
        calculated_price_incl_tax: 0,
        original_tax: 0,
        calculated_tax: 0,
        tax_rates: [],
        prices: [],
        title: "",
        quantity: 1,
        inventory_quantity: 0,
      },
    })
    setOpenedDrawer("")
    setLocked(false)
  }

  const handleCustomGiftCardAddToCart = async () => {
    const amount = customGiftCardAmountInputRef.current.value
    const response = await customGiftCardMutation.mutateAsync({
      amount,
    })

    if (response) {
      await updateCart.mutateAsync({})

      pushNotification({
        id: "add-to-cart",
        body: (
          <>
            <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
              Added to cart
            </Paragraph>
            <Paragraph sx={{ marginBlockEnd: 4 }}>
              A gift card was added to your cart
            </Paragraph>
            <Button
              sx={{ width: "100%", marginBlockStart: 6 }}
              onClick={() => {
                dismissNotification("add-to-cart")
                showCart()
              }}
            >
              See the cart
            </Button>
          </>
        ),
        dismiss: {
          duration: 3000,
        },
      })

      trackAddToCart(
        cart,
        selectedColor,
        {
          prices: [
            {
              currency_code: cart?.region?.currency_code,
              amount,
            },
          ],
          sku: "GI-FT-5",
        },
        1,
        props.collection,
        false
      )
    }
  }

  // Add to cart
  const handleAddToCart = async (
    product: Pick<
      ContentfulCollectionProduct,
      "handle" | "productUrl" | "isGiftcard" | "imagePacks" | "title"
    >,
    variant: Pick<
      SelectedVariant,
      | "id"
      | "sku"
      | "quantity"
      | "title"
      | "metadata"
      | "inventory_quantity"
      | "type"
    >,
    quickAddToCart = false
  ) => {
    if (createLineItem.isLoading) {
      return
    }

    let notificationContent

    if (!variant?.id) {
      handleSizeSelectorScroll()
      return
    }

    setLocked(false)
    setOpenedDrawer("")

    let productUrl = product.productUrl || `/product/${product.handle}` || ""

    const isShoes =
      variant?.sku?.startsWith("UJ-") ||
      (variant?.sku?.startsWith("NA-") &&
        product?.title?.toLowerCase().indexOf("napkin") < 0)

    const quantity = variant.quantity ?? 1
    let metadata: any = {
      type: variant?.sku?.startsWith("TT") ? getToweLabel(variant.title) : "",
      productUrl: productUrl,
    }

    let title = variant?.sku?.startsWith("TT")
      ? getToweLabel(variant.title)
      : variant.title

    if (
      isBedding ||
      (hasMeasureUnit(variant?.sku) && !variant?.sku?.startsWith("TT"))
    ) {
      title =
        cart?.shipping_address?.country_code?.toLowerCase() === "us"
          ? sizeToInches(variant) + "in"
          : variant.title
      metadata.sizeInInches = sizeToInches(variant) + "in"
    }

    if (isShoes) {
      title = getShoesSize(variant, cart?.shipping_address?.country_code)
      metadata.customSize = title
    }

    try {
      await createLineItem.mutateAsync({
        variant_id: variant.id,
        quantity: quantity,
        metadata: metadata,
      })

      notificationContent = product.isGiftcard
        ? (notificationContent = "A gift card was added to your cart")
        : (notificationContent = `${product.title} - ${quantity} x ${title} has been added to your cart`)
    } catch (error) {
      notificationContent =
        "Something went wrong. Item can't be added to the cart."
      console.log(error)
    }
    pushNotification({
      id: "add-to-cart",
      body: (
        <>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            Added to cart
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {notificationContent}
          </Paragraph>
          {/* <Flex sx={{ alignItems: "center" }}>
            <Icon
              name="info"
              size={4}
              sx={{ marginBlockStart: "-2px", marginInlineEnd: 2 }}
            />
            <Paragraph>Pickup in store not possible</Paragraph>
          </Flex> */}
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("add-to-cart")
              showCart()
            }}
          >
            See the cart
          </Button>
        </>
      ),
      dismiss: {
        duration: 3000,
      },
    })

    trackAddToCart(
      cart,
      product,
      variant,
      quantity,
      props.collection,
      quickAddToCart
    )

    if (isSingle) {
      setIsSizeDrawerOpened(false)
      setLocked(false)
    } else {
      handleSelectedVariantReset()
      setIsSizeDrawerOpened(false)
    }
  }
  // Notify OOS
  const handleNotifyMe = async () => {
    if (!hasConsent) {
      setConsentErrorMessage(
        "You must agree to the Terms and Conditions and Privacy Policy."
      )
      return
    }
    try {
      emailSchema.parse(email)
    } catch (error) {
      setConsentErrorMessage("Invalid email.")
      return
    }

    const { selectedVariant } = state

    await Medusa.restock.signUp(selectedVariant?.id, email)

    trackRestockSignup(cart, selectedVariant, email)
    setSignedUp([...signedUp, selectedVariant?.id])

    if (isNotifyOpenedFromDrawer) {
      setTimeout(() => setOpenedDrawer("size-picker"), 1000)
      setIsNotifyOpenedFromDrawer(false)
    }

    if (newsletterChecked) {
      const payload = {
        email: email,
        ids: {},
      }

      Medusa.newsletter
        .signup(payload)
        .then(({ data }) => {
          if (data.user_exists) {
            pushNotification({
              id: "sign_up_complete",
              body: "This email is already subscribed",
              dismiss: {
                duration: 3000,
              },
            })
          } else {
            pushNotification({
              id: "sign_up_complete",
              body: "Success",
              dismiss: {
                duration: 3000,
              },
            })
          }
        })
        .catch(() => {
          pushNotification({
            id: "sign_up_failed",
            body: "Sorry, something went wrong. Make sure the input is correct and try again",
            dismiss: {
              duration: 3000,
            },
          })
        })
    }
  }

  // If Add to cart disabled jump to color picker
  const handleSizeSelectorScroll = () => {
    const element = document.getElementById("size-selector")

    element &&
      observedRefs?.sizePicker?.isInView === false &&
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 60,
        behavior: "smooth",
      })
  }

  // Options for Rich text in drawers
  const optionsRichText = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: function createParagraph(node, children) {
        return <Paragraph sx={{ marginBlockEnd: 2 }}>{children}</Paragraph>
      },
    },
  }

  // Size guide
  const {
    drawerContent: sizeGuideDrawer,
    drawerVisible: sizeGuideDrawerVisible,
    setDrawerVisible: setSizeGuideDrawerVisible,
    setDrawerContent: setSizeGuideDrawerContent,
  } = useDrawerHook({ config: { asModal: false } })

  const handleToggleSizeGuide = () => {
    setSizeGuideDrawerVisible(!sizeGuideDrawerVisible)
  }

  const pushToObservedRefs = (e) =>
    e &&
    !observedRefsArray.current.includes(e) &&
    observedRefsArray.current.push(e)
  const observedRefsArray = React.useRef([])
  const observedRefs = useInView(observedRefsArray.current)

  React.useEffect(() => {
    if (!selectedColor) {
      return
    }

    // Set gender
    const pg = localStorage.getItem("tekla::pg")
    const prefersGender = pg === "m" ? "male" : "female"

    setGender(prefersGender)

    let images =
      imagesMale?.map((v, i) => {
        return {
          male: v,
          female: imagesFemale?.[i],
        }
      }) || []

    setImages(images)

    addToRecentlyViewed({
      id: selectedColor?.id,
      thumbnail: selectedColor?.thumbnail,
      url: window.location.pathname,
      title: getTitle(selectedColor, fabricCollection),
    })
    trackProductViewed(selectedColor, collection)
  }, [selectedColor])

  React.useLayoutEffect(() => {
    // Keep scroll position on mobile when switching colors
    const scrollPosition = JSON.parse(
      sessionStorage.getItem("tekla::product::scroll")
    )

    if (
      window?.innerWidth < 768 &&
      scrollPosition?.scrollY &&
      scrollPosition?.path === collectionPath
    ) {
      window.scroll({
        top: scrollPosition.scrollY,
        left: 0,
        behavior: "instant",
      })
    }
    // Reset scroll position
    sessionStorage.setItem(
      "tekla::product::scroll",
      JSON.stringify({ scrollY: 0, path: collectionPath })
    )
  }, [])

  React.useEffect(() => {
    if (isSingle && selectedColor && state.inventory) {
      handleSizePickerClick(selectedColor.variants?.[0])
    }
  }, [selectedColor, state.inventory])

  React.useEffect(() => {
    const inventory = getVariantsInventory(medusaProduct)
    setState({
      ...state,
      product: selectedColor,
      inventory: inventory,
    })
  }, [selectedColor])

  // Size guide use effect if cart changed
  React.useEffect(() => {
    if (sizeGuideGroups?.length) {
      setSizeGuideDrawerContent(
        <SizeGuide
          sizeGuideGroups={sizeGuideGroups}
          initialGender={gender}
          initialSizing={
            cart?.shipping_address?.country_code?.toLowerCase() === "us"
              ? "inch"
              : "cm"
          }
          title={<h2>Size guide</h2>}
        />
      )
    }
  }, [cart?.region_id, gender])

  const [isNotifyOpenedFromDrawer, setIsNotifyOpenedFromDrawer] =
    React.useState(false)

  const sortedVariants: EnrichedMedusaVariant[] = React.useMemo(() => {
    return sortVariants(variants, isBedding)
  }, [variants, isBedding])

  const recommendedInRegion =
    cart?.region?.metadata?.recommended_sizes?.[medusaProduct?.type_id] || []

  const recommendedSizes: EnrichedMedusaVariant[] = sortedVariants.reduce(
    (acc, next) => {
      if (recommendedInRegion?.includes(next.title?.toLowerCase())) {
        acc.push(next)
      }
      return acc
    },
    []
  )

  const sizes = recommendedSizes?.length ? recommendedSizes : sortedVariants

  const isSale = medusaProduct?.variants?.some(
    (i) => i.calculated_price_type === "sale"
  )

  const hasCm = isBedding || hasMeasureUnit(selectedColor?.sku)
  const showInches = hasCm && cart?.region?.name === "United States"

  const handleProductButtonOnClick = ({
    isInDrawer,
  }: {
    isInDrawer?: boolean
  }) => {
    if (
      isGiftcard &&
      customGiftCardAmountInputRef.current?.value &&
      !customGiftCardHasError
    ) {
      handleCustomGiftCardAddToCart()

      return
    }

    if (hasNotifyMe && isVariantOutOfStock(state?.selectedVariant)) {
      setOpenedDrawer("not-available")
      setLocked(true)

      if (isInDrawer) {
        setIsNotifyOpenedFromDrawer(true)
      }

      return
    }

    if (
      !isVariantSelected &&
      !isGiftcard &&
      (sizePickerVariant === "list" || sizePickerVariant === "named-list") &&
      isMobile
    ) {
      setOpenedDrawer("size-picker-mobile")
      setLocked(true)

      return
    }

    if (
      !isVariantSelected &&
      !isVariantOutOfStock(state?.selectedVariant) &&
      sortedVariants?.length > 4 &&
      sizePickerVariant !== "inline-list" &&
      sizePickerVariant !== "wrapped-inline-list" &&
      !isGiftcard &&
      !isTowel
    ) {
      setOpenedDrawer("size-picker")
      setLocked(true)

      return
    }

    if (!isVariantSelected && (isProductSoldOut || isVariantSoldOut)) {
      return
    }

    handleAddToCart(selectedColor, state?.selectedVariant)
  }

  const fabricCollection =
    selectedColor?.categories?.find((c) => c.metadata?.fabric) ||
    selectedColor?.categories?.find(
      (i) => i.handle !== "internal-archive" && i.parent_category_id
    )

  return (
    <Layout isProductsPage={true} almostWhite>
      <Box
        sx={{
          display: ["block", "flex"],
          justifyContent: "center",
          backgroundColor: "grayscale.100",
          paddingInlineEnd: [null, 10, 30],
        }}
      >
        <Gallery
          galleryPhotos={images}
          modelGender={gender}
          setModelGender={(v) => {
            setGender(v)
            localStorage.setItem("tekla::pg", v === "male" ? "m" : "f")
          }}
          currentColor={color}
          colorOptions={colorOptions}
          isModelSwitcherVisible={
            imagesMale?.length && imagesFemale?.length
              ? imagesMale.length > 1 && imagesFemale.length > 1
              : false
          }
        />
        <Box
          sx={{
            flex: (theme) => [
              null,
              `0 0 calc(50% - ${theme.sizes?.[5]}px)`,
              `0 0 calc(50% - ${theme.sizes?.[15]}px)`,
            ],
            position: "relative",
            zIndex: "productContent",
            backgroundColor: ["grayscale.100", "unset"],
            paddingBlockStart: 5,
            paddingInlineStart: [4, 10],
            paddingInlineEnd: [4, 0],
          }}
        >
          <Box
            sx={{
              height: "100%",
              fontSize: "sm",
              maxWidth:
                (isSingle && !isBathMat) || selectedColor?.isGiftcard
                  ? 119
                  : 138,
              paddingBlockEnd: 12,
              marginInline: ["auto", 0],
            }}
          >
            <Box>
              {!isGiftcard && (
                <Paragraph
                  sx={{ display: ["none", "block"], marginBlockEnd: 8 }}
                >
                  <Link
                    variant="underline-on-hover"
                    to={
                      category?.handle === "internal-archive"
                        ? "/internal-archive"
                        : "/"
                    }
                  >
                    {category?.name}
                  </Link>{" "}
                </Paragraph>
              )}

              <Flex sx={{ justifyContent: "space-between", marginBlockEnd: 4 }}>
                <Heading as="h1" sx={{ fontSize: ["base", "md"] }}>
                  {getTitle(selectedColor, fabricCollection)}
                </Heading>
                <Paragraph
                  sx={{
                    minHeight: [6, "27px"],
                    flexGrow: 0,
                    flexShrink: 0,
                    fontSize: ["base", "md"],
                    textAlign: "right",
                    marginInlineStart: 2,
                  }}
                >
                  {formatSelectionPrice()}
                </Paragraph>
              </Flex>
              {selectedColor?.description?.description && (
                <Paragraph sx={{ marginBlockEnd: 10 }}>
                  {selectedColor.description.description}
                </Paragraph>
              )}
              {selectedColor.isGiftcard && (
                <CustomGiftCard
                  value={giftcardValue}
                  setValue={setGiftcardValue}
                  inputRef={customGiftCardAmountInputRef}
                  hasError={customGiftCardHasError}
                  setHasError={setCustomGiftCardHasError}
                />
              )}
              {((!selectedColor?.isGiftcard && !isDownCollection) ||
                selectedColor.sku?.startsWith("DN-BC")) && (
                <>
                  <Flex
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 5,
                      marginBlockEnd: 5,
                    }}
                  >
                    <Paragraph>
                      Colour:{" "}
                      <Text sx={{ color: "grayscale.600" }}>{color}</Text>
                    </Paragraph>
                    <Tag>All sales are final</Tag>
                  </Flex>
                  {colorOptions?.length > 1 && (
                    <Box>
                      {Object.keys(colorPatterns)
                        .sort((a, b) => {
                          // Default patterns first, then alphabetically
                          if (
                            defaultPatterns.includes(a) &&
                            !defaultPatterns.includes(b)
                          ) {
                            return -1
                          }
                          if (
                            defaultPatterns.includes(b) &&
                            !defaultPatterns.includes(a)
                          ) {
                            return 1
                          }
                          return a.localeCompare(b)
                        })
                        .map((k) => {
                          return (
                            <ColorPicker
                              key={k}
                              label={
                                Object.keys(colorPatterns).length > 1 &&
                                capitalize(k)
                              }
                              labelStyles={{
                                width: 15,
                                fontSize: ["xs", "sm"],
                              }}
                              sx={{
                                marginBlockEnd: [4, 6],
                                marginInlineEnd: [-4, 0],
                              }}
                            >
                              {colorPatterns[k].map((o, i) => {
                                let url = `/product/${o.handle}`
                                return (
                                  o.color && (
                                    <ColorPickerItem
                                      key={o.id}
                                      color={o.color.hex}
                                      image={
                                        o.color.image && (
                                          <Image src={o.color.image.url} />
                                        )
                                      }
                                      isActive={
                                        o.color?.id === selectedColor.color?.id
                                      }
                                      isLast={colorPatterns[k].length === i + 1}
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "tekla::product::scroll",
                                          JSON.stringify({
                                            scrollY: window.scrollY,
                                            path: collectionPath,
                                          })
                                        )
                                        navigate(url, {
                                          state: {
                                            scrollTo: {
                                              x: 0,
                                              y: window.scrollY,
                                            },
                                          },
                                        })
                                      }}
                                      onMouseEnter={() =>
                                        setColor(o.color.title)
                                      }
                                      onMouseLeave={() =>
                                        setColor(selectedColor?.color?.title)
                                      }
                                    />
                                  )
                                )
                              })}
                            </ColorPicker>
                          )
                        })}
                    </Box>
                  )}
                </>
              )}
              {!selectedColor.isGiftcard && (
                <>
                  <Box
                    id="size-selector"
                    sx={{
                      display:
                        sizePickerVariant !== "inline-list" &&
                        sizePickerVariant !== "named-inline-list" &&
                        sizePickerVariant !== "wrapped-inline-list" &&
                        sizePickerVariant !== "single"
                          ? ["none", "flex"]
                          : "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBlockStart:
                        colorOptions.length === 1 ? 10 : [10, 12],
                      marginBlockEnd: 2,
                    }}
                  >
                    <Paragraph>
                      {recommendedSizes.length > 0
                        ? `Sizes (${getSizingUnit(
                            cart
                          )}) recommended based on your region: `
                        : hasCm
                        ? state.selectedVariant?.title
                          ? `Size (${getSizingUnit(cart)}): `
                          : `Size (${getSizingUnit(cart)})`
                        : selectedColor?.isGiftcard
                        ? "Select amount:"
                        : state.selectedVariant?.title && isShoes
                        ? "Size (EU): "
                        : state.selectedVariant?.title
                        ? "Size: "
                        : isShoes
                        ? "Size (EU)"
                        : "Size"}
                      <Text sx={{ color: "grayscale.600" }}>
                        {showInches && state.selectedVariant?.title
                          ? sizeToInches(state.selectedVariant)
                          : isShoes
                          ? getShoesSize(
                              state.selectedVariant,
                              cart?.shipping_address?.country_code
                            )
                          : state.selectedVariant?.title}
                      </Text>
                    </Paragraph>
                    {sizePickerVariant === "single" &&
                      (isVariantLowInStock(state?.selectedVariant) ||
                        isVariantOutOfStock(state?.selectedVariant)) && (
                        <Tag>
                          {isVariantLowInStock(state?.selectedVariant)
                            ? `${state?.selectedVariant?.inventory_quantity} left in stock`
                            : isVariantOutOfStock(state?.selectedVariant)
                            ? "Out of stock"
                            : ""}
                        </Tag>
                      )}
                    {sizeGuideGroups?.length && !isSingle ? (
                      <Paragraph sx={{ flexBasis: 19, textAlign: "right" }}>
                        <Link
                          variant="underline-on-hover"
                          onClick={handleToggleSizeGuide}
                        >
                          Size guide
                        </Link>
                      </Paragraph>
                    ) : null}
                  </Box>
                  {sizePickerVariant === "list" ? (
                    <Box
                      ref={(e) => pushToObservedRefs(e)}
                      data-observer-id="sizePicker"
                      sx={{ display: ["none", "block"] }}
                    >
                      <SizePickerList>
                        {sizes.map((v, i) => {
                          const variant = state.product?.variants?.find(
                            (i) => i.id === v.id
                          )

                          let size = showInches ? sizeToInches(v) : v.title
                          const titleLowerCase = v.title?.toLowerCase()

                          return (
                            i < 4 && (
                              <SizePickerListRow
                                key={v.id}
                                label={getPriceLabel(
                                  variant,
                                  cart?.region?.currency_code,
                                  discountValue
                                )}
                                sideContent={
                                  state?.selectedVariant?.id === v.id &&
                                  !isVariantOutOfStock(variant) ? (
                                    <NumberInput
                                      value={state?.selectedVariant?.quantity}
                                      min={1}
                                      max={
                                        state?.selectedVariant
                                          ?.inventory_quantity
                                      }
                                      onNumberChange={(value) => {
                                        setState({
                                          ...state,
                                          selectedVariant: {
                                            ...state.selectedVariant,
                                            quantity: value,
                                          },
                                        })
                                      }}
                                      removeDisabled={
                                        state?.selectedVariant?.quantity === 1
                                      }
                                      addDisabled={
                                        state?.selectedVariant?.quantity ===
                                        state?.selectedVariant
                                          ?.inventory_quantity
                                      }
                                      sx={{ maxWidth: 22 }}
                                    />
                                  ) : v.sku?.startsWith("TT-") ? (
                                    <Text sx={{ color: "grayscale.600" }}>
                                      {v.title}
                                    </Text>
                                  ) : null
                                }
                                tagChildren={
                                  isSale
                                    ? ""
                                    : isVariantLowInStock(variant)
                                    ? `${variant.inventory_quantity} left in stock`
                                    : isVariantOutOfStock(variant)
                                    ? "Out of stock"
                                    : ""
                                }
                                tagProps={{
                                  color: "primary",
                                  sx: {
                                    display:
                                      isVariantLowInStock(variant) ||
                                      isVariantOutOfStock(variant)
                                        ? "inline-block"
                                        : "none",
                                  },
                                }}
                                isDisabled={isVariantOutOfStock(variant)}
                                isActive={state.selectedVariant?.id === v.id}
                                onClickCapture={() => handleSizePickerClick(v)}
                              >
                                {v.sku?.startsWith("TT-")
                                  ? getToweLabel(titleLowerCase)
                                  : (titleLowerCase === "270x235" ||
                                      titleLowerCase === "193x203x40" ||
                                      (titleLowerCase === "50x90" &&
                                        selectedColor.title
                                          ?.toLowerCase()
                                          .includes("pillow"))) &&
                                    cart?.region?.name === "United States"
                                  ? "King"
                                  : (titleLowerCase === "230x235" ||
                                      titleLowerCase === "153x203x40" ||
                                      (titleLowerCase === "50x70" &&
                                        selectedColor.title
                                          ?.toLowerCase()
                                          .includes("pillow"))) &&
                                    cart?.region?.name === "United States"
                                  ? "Queen"
                                  : titleLowerCase === "179x226" &&
                                    cart?.region?.name === "United States"
                                  ? "Twin / XL"
                                  : size}
                              </SizePickerListRow>
                            )
                          )
                        })}
                        {(sortedVariants.length > 4 ||
                          recommendedSizes.length < sortedVariants.length) && (
                          <SizePickerListRow
                            isViewAll
                            hasBorder={false}
                            sideContent={
                              <Text sx={{ fontSize: "lg", lineHeight: 1 }}>
                                +
                              </Text>
                            }
                            onClick={() => {
                              setOpenedDrawer("size-picker")
                              setLocked(true)
                              trackBeddingViewAllSizes(
                                selectedColor,
                                props.collection
                              )
                            }}
                          >
                            View all sizes
                          </SizePickerListRow>
                        )}
                      </SizePickerList>
                    </Box>
                  ) : sizePickerVariant === "inline-list" ? (
                    <Flex
                      ref={(e) => pushToObservedRefs(e)}
                      data-observer-id="sizePicker"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBlockEnd: 10,
                      }}
                    >
                      <SizePicker
                        sx={{
                          flex: 1,
                          justifyContent:
                            !selectedColor.sku.startsWith("BH-") &&
                            "space-between",
                          gap: selectedColor.sku.startsWith("BH-") && 9,
                          maxWidth: 75,
                        }}
                      >
                        {sortedVariants.map((v) => {
                          const quantity = state.inventory[v.id]
                          const variant = state.product?.variants?.find(
                            (i) => i.id === v.id
                          )

                          return (
                            <SizePickerItem
                              key={v.id}
                              onClick={() => handleSizePickerClick(v)}
                              isActive={state.selectedVariant?.id === v.id}
                              disabled={quantity < 1}
                              hasDot={quantity > 0 && state.inventory[v.id] < 5}
                              sx={{
                                fontSize: selectedColor?.isGiftcard
                                  ? "sm"
                                  : "md",
                              }}
                            >
                              {selectedColor?.isGiftcard
                                ? formatMoneyAmount(
                                    {
                                      currencyCode: cart?.region?.currency_code,
                                      amount: variant?.original_price_incl_tax,
                                    },
                                    0
                                  )
                                : v.sku.split("-").slice(-1)}
                            </SizePickerItem>
                          )
                        })}
                      </SizePicker>
                      <Flex
                        sx={{
                          minWidth: [26, 30],
                          flexGrow: 0,
                          flexShrink: 0,
                          flexBasis: [26, 30],
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tag
                          sx={{
                            opacity:
                              isVariantLowInStock(state?.selectedVariant) ||
                              isVariantOutOfStock(state?.selectedVariant)
                                ? 1
                                : 0,
                            visibility:
                              isVariantLowInStock(state?.selectedVariant) ||
                              isVariantOutOfStock(state?.selectedVariant)
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          {isVariantLowInStock(state?.selectedVariant)
                            ? `${state.selectedVariant?.inventory_quantity} left in stock`
                            : isVariantOutOfStock(state?.selectedVariant)
                            ? "Out of stock"
                            : ""}
                        </Tag>
                      </Flex>
                    </Flex>
                  ) : sizePickerVariant === "named-list" ? (
                    <Box
                      ref={(e) => pushToObservedRefs(e)}
                      data-observer-id="sizePicker"
                      sx={{ display: ["none", "block"] }}
                    >
                      <SizePickerList>
                        {sizes.map((v, i) => {
                          const variant = state.product?.variants?.find(
                            (i) => i.id === v.id
                          )

                          const size = showInches ? sizeToInches(v) : v.title

                          return (
                            <SizePickerListRow
                              key={v.id}
                              label={getPriceLabel(
                                variant,
                                cart?.region?.currency_code,
                                discountValue
                              )}
                              sideContent={
                                state?.selectedVariant?.id === v.id &&
                                !isVariantOutOfStock(variant) ? (
                                  <NumberInput
                                    value={state?.selectedVariant?.quantity}
                                    min={1}
                                    max={
                                      state?.selectedVariant?.inventory_quantity
                                    }
                                    onNumberChange={(value) => {
                                      setState({
                                        ...state,
                                        selectedVariant: {
                                          ...state.selectedVariant,
                                          quantity: value,
                                        },
                                      })
                                    }}
                                    removeDisabled={
                                      state?.selectedVariant?.quantity === 1
                                    }
                                    addDisabled={
                                      state?.selectedVariant?.quantity ===
                                      state?.selectedVariant?.inventory_quantity
                                    }
                                    sx={{ maxWidth: 22 }}
                                  />
                                ) : v.sku?.startsWith("TT-") ? (
                                  <Text sx={{ color: "grayscale.600" }}>
                                    {size}
                                  </Text>
                                ) : null
                              }
                              tagChildren={
                                isSale
                                  ? ""
                                  : isVariantLowInStock(variant)
                                  ? `${variant.inventory_quantity} left in stock`
                                  : isVariantOutOfStock(variant)
                                  ? "Out of stock"
                                  : ""
                              }
                              tagProps={{
                                color: "primary",
                                sx: {
                                  display:
                                    isVariantLowInStock(variant) ||
                                    isVariantOutOfStock(variant)
                                      ? "inline-block"
                                      : "none",
                                },
                              }}
                              hasBorder={i !== sizes.length - 1}
                              isDisabled={isVariantOutOfStock(variant)}
                              isActive={state.selectedVariant?.id === v.id}
                              onClickCapture={() => handleSizePickerClick(v)}
                            >
                              {v.sku?.startsWith("TT-")
                                ? getToweLabel(v.title)
                                : v.title}
                            </SizePickerListRow>
                          )
                        })}
                      </SizePickerList>
                    </Box>
                  ) : sizePickerVariant === "named-inline-list" ? (
                    <Flex
                      ref={(e) => pushToObservedRefs(e)}
                      data-observer-id="sizePicker"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBlockEnd: 10,
                      }}
                    >
                      <SizePicker
                        sx={{
                          flex: 1,
                          gap: 8,
                          maxWidth: 110,
                        }}
                      >
                        {sortedVariants.map((v) => {
                          const quantity = state.inventory[v.id]
                          return (
                            <SizePickerItem
                              onClick={() => handleSizePickerClick(v)}
                              isActive={state.selectedVariant?.id === v.id}
                              disabled={quantity < 1}
                              hasDot={quantity > 0 && state.inventory[v.id] < 5}
                              sx={{
                                fontSize: selectedColor?.isGiftcard
                                  ? "sm"
                                  : "md",
                              }}
                            >
                              {v.sku.startsWith("BTK-") ||
                              v.sku.startsWith("SWSET-")
                                ? v.title.trim().split(" ").shift()
                                : v.title}
                            </SizePickerItem>
                          )
                        })}
                      </SizePicker>
                      <Flex
                        sx={{
                          minWidth: [26, 30],
                          flexGrow: 0,
                          flexShrink: 0,
                          flexBasis: [26, 30],
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tag
                          sx={{
                            opacity:
                              isVariantLowInStock(state?.selectedVariant) ||
                              isVariantOutOfStock(state?.selectedVariant)
                                ? 1
                                : 0,
                            visibility:
                              isVariantLowInStock(state?.selectedVariant) ||
                              isVariantOutOfStock(state?.selectedVariant)
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          {isVariantLowInStock(state?.selectedVariant)
                            ? `${state.selectedVariant?.inventory_quantity} left in stock`
                            : isVariantOutOfStock(state?.selectedVariant)
                            ? "Out of stock"
                            : ""}
                        </Tag>
                      </Flex>
                    </Flex>
                  ) : sizePickerVariant === "wrapped-inline-list" ? (
                    <Flex
                      ref={(e) => pushToObservedRefs(e)}
                      data-observer-id="sizePicker"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBlockEnd: 10,
                      }}
                    >
                      <SizePicker
                        sx={{
                          flex: 1,
                          justifyContent: "start",
                          flexWrap: "wrap",
                          rowGap: 6,
                          columnGap: 5,
                          maxWidth: 75,
                        }}
                      >
                        {sortedVariants.map((v) => {
                          const quantity = state.inventory[v.id]
                          const variant = state.product?.variants?.find(
                            (i) => i.id === v.id
                          )
                          const size = isShoes
                            ? getShoesSize(
                                variant,
                                cart?.shipping_address?.country_code
                              )
                            : v.title

                          return (
                            <SizePickerItem
                              onClick={() => handleSizePickerClick(v)}
                              isActive={state.selectedVariant?.id === v.id}
                              disabled={quantity < 1}
                              hasDot={quantity > 0 && state.inventory[v.id] < 5}
                              paragraphSx={{ minWidth: 8 }}
                            >
                              {size}
                            </SizePickerItem>
                          )
                        })}
                      </SizePicker>
                      {!selectedColor?.isGiftcard && (
                        <Flex
                          sx={{
                            minWidth: [26, 30],
                            flexGrow: 0,
                            flexShrink: 0,
                            flexBasis: [26, 30],
                            justifyContent: "flex-end",
                            alignSelf: "flex-start",
                          }}
                        >
                          <Tag
                            sx={{
                              opacity:
                                isVariantLowInStock(state?.selectedVariant) ||
                                isVariantOutOfStock(state?.selectedVariant)
                                  ? 1
                                  : 0,
                              visibility:
                                isVariantLowInStock(state?.selectedVariant) ||
                                isVariantOutOfStock(state?.selectedVariant)
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            {isVariantLowInStock(state?.selectedVariant)
                              ? `${state.selectedVariant?.inventory_quantity} left in stock`
                              : isVariantOutOfStock(state?.selectedVariant)
                              ? "Out of stock"
                              : ""}
                          </Tag>
                        </Flex>
                      )}
                    </Flex>
                  ) : null}
                </>
              )}
            </Box>
            <Box sx={{ position: "sticky", top: 13 }}>
              <Box
                sx={{
                  marginBlockStart: !isSleep && 10,
                  marginBlockEnd: 10,
                  "> *:last-child": {
                    marginBlockEnd: 0,
                  },
                }}
              >
                <ProductButton
                  sx={{ width: ["100%", 65], marginBlockEnd: 4 }}
                  hasNotifyMe={hasNotifyMe}
                  isProductSoldOut={isProductSoldOut}
                  isProductOutOfStock={isProductOutOfStock(state?.inventory)}
                  isVariantSelected={isVariantSelected}
                  isVariantOutOfStock={isVariantOutOfStock(
                    state?.selectedVariant
                  )}
                  isLoading={
                    customGiftCardMutation.isLoading ||
                    createLineItem.isLoading ||
                    !MedusaProductsLoaded
                  }
                  isVisuallyDisabled={
                    createLineItem.isLoading || !MedusaProductsLoaded
                  }
                  isVariantSoldOut={isVariantSoldOut}
                  sizePickerVariant={sizePickerVariant}
                  isGiftcard={isGiftcard}
                  isMobile={isMobile}
                  isInDrawer={false}
                  isProductRelated={false}
                  isProductSingleSize={variants?.length < 2}
                  giftcardValue={giftcardValue}
                  giftcardHasError={customGiftCardHasError}
                  onClick={() =>
                    handleProductButtonOnClick({ isInDrawer: false })
                  }
                />
                {hasKlarna(cart?.shipping_address?.country_code) &&
                  !isVariantOutOfStock(state?.selectedVariant) &&
                  !isProductOutOfStock(state?.inventory) && (
                    <Paragraph sx={{ marginBlockEnd: 1 }}>
                      Order now. Pay in 30 days with{" "}
                      <Box
                        sx={{
                          display: "inline-block",
                          marginInlineStart: "2px",
                        }}
                      >
                        <Image src={KlarnaLogo} />
                      </Box>
                    </Paragraph>
                  )}
                {!isGiftcard && !isProductOutOfStock(state?.inventory) && (
                  <Paragraph>
                    {estimated &&
                      !isVariantOutOfStock(state?.selectedVariant) &&
                      `Estimated delivery time: ${estimated} business days`}
                  </Paragraph>
                )}
              </Box>
              <Box sx={{ marginBlockEnd: 10 }}>
                {information?.map((item, index) => (
                  <Paragraph
                    key={index}
                    sx={{
                      marginBlockEnd: 4,
                    }}
                  >
                    <Link
                      onClick={() => {
                        setOpenedDrawer(item.title)
                        setLocked(true)
                      }}
                    >
                      <Text>{item.title}</Text>
                      <Text sx={{ marginInlineStart: 3 }}>+</Text>
                    </Link>
                  </Paragraph>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <ProductsTabs
        selectedColor={selectedColor}
        contentModules={contentModules}
      />

      {information?.map((i) => {
        return (
          <Drawer
            key={i.id}
            isOpened={openedDrawer === i.title}
            hasBlurOnMobile={false}
            onCloseClick={() => {
              setOpenedDrawer("")
              setLocked(false)
            }}
            onSwipeRight={() => {
              setOpenedDrawer("")
              setLocked(false)
            }}
          >
            <Box
              sx={{
                ul: {
                  listStyleType: "none",
                  marginInline: 0,
                  marginBlock: 6,
                },
                a: {
                  color: "currentcolor",
                },
              }}
            >
              {i.body && renderRichText(i.body, optionsRichText)}
            </Box>
          </Drawer>
        )
      })}
      {shouldShowGiftCardDrawer ? (
        <Drawer
          isOpened={openedDrawer === "gift-wrapping"}
          hasBlurOnMobile={false}
          onCloseClick={() => {
            setOpenedDrawer("")
            setLocked(false)
          }}
          onSwipeRight={() => {
            setOpenedDrawer("")
            setLocked(false)
          }}
        >
          <Box
            sx={{
              ul: {
                listStyleType: "none",
                marginInline: 0,
                marginBlock: 6,
              },
              a: {
                color: "currentcolor",
              },
            }}
          >
            <p>
              We offer complimentary gift wrapping on all orders. Please select
              “Add complimentary gift wrapping” at checkout. 
            </p>
            <p>
              Bathrobes and sleepwear sets are packed and delivered in gift
              boxes closed with a ribbon. A sleepwear set consists of one shirt
              and one pair of pants/shorts. If ordering multiple pieces,
              sleepwear sets will be paired automatically.
            </p>
            <p>
              All other products, excluding duvets, pillows and beach towels,
              are delivered with unpacked gift envelopes with ribbon closures to
              be used at your own discretion. This is to maintain the envelope's
              shape and allow for personalisation. Gift envelopes are selected
              according to the size of the order.
            </p>
          </Box>
        </Drawer>
      ) : null}
      <Drawer
        isOpened={openedDrawer === "not-available"}
        size="lg"
        onCloseClick={() => {
          setOpenedDrawer("")
          setLocked(false)

          if (!isSingle) {
            setState({
              ...state,
              selectedVariant: {
                original_price: 0,
                original_price_incl_tax: 0,
                calculated_price: 0,
                calculated_price_incl_tax: 0,
                original_tax: 0,
                calculated_tax: 0,
                tax_rates: [],
                prices: [],
                title: "",
                quantity: 1,
                inventory_quantity: 0,
              },
            })
          }
        }}
        onSwipeRight={() => {
          setOpenedDrawer("")
          setLocked(false)
        }}
      >
        <Heading sx={{ fontSize: "md", marginBlockEnd: 9 }}>
          Notify me when the product is available
        </Heading>
        <TextInlineButtonField
          inputProps={{
            type: "email",
            placeholder: "Email",
            value: email,
            onChange: (e) => setEmail(e.target.value),
            sx: { flex: 1 },
          }}
          buttonProps={{
            children: "Submit",
            isSuccessful: signedUp.includes(state.selectedVariant?.id),
            isVisuallyDisabled:
              signedUp.includes(state.selectedVariant?.id) || !hasConsent,
            disabled: signedUp.includes(state.selectedVariant?.id),
            onClick: handleNotifyMe,
          }}
          sx={{ marginBlockEnd: 6 }}
        />
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              opacity: signedUp.includes(state.selectedVariant?.id) ? 0 : 1,
              visibility: signedUp.includes(state.selectedVariant?.id)
                ? "hidden"
                : "visible",
              transition: "opacity .2s, visibility .2s",
            }}
          >
            <Checkbox
              label="Sign me up for Tekla Fabrics news & digital communications"
              labelStyle={{ fontSize: "xs", marginBlockEnd: 4 }}
              onChange={() => setNewsletterChecked((prev) => !prev)}
            />
            <Checkbox
              label={
                <>
                  I confirm that I have read and understood the{" "}
                  <Link variant="underline" to="/terms-and-conditions">
                    Terms and Conditions
                  </Link>{" "}
                  and the{" "}
                  <Link variant="underline" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </>
              }
              labelStyle={{ fontSize: "xs" }}
              onChange={() => setHasConsent((prev) => !prev)}
              errorMessage={consentErrorMessage}
              errorMessageStyle={{ fontSize: "xs" }}
            />
          </Box>
          <Paragraph
            sx={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              color: "grayscale.600",
              fontSize: "sm",
              visibility: signedUp.includes(state.selectedVariant?.id)
                ? "visible"
                : "hidden",
              opacity: signedUp.includes(state.selectedVariant?.id) ? 1 : 0,
              transition: "opacity .2s, visibility .2s",
            }}
          >
            {/* TODO: Translation string or use existing one with product name */}
            You will receive an email as soon as the product is restocked
          </Paragraph>
        </Box>
      </Drawer>
      <Drawer
        isOpened={openedDrawer === "size-picker"}
        onCloseClick={() => {
          handleSelectedVariantReset()
          setOpenedDrawer("")
          setLocked(false)
        }}
      >
        <Paragraph sx={{ marginBlockEnd: 3 }}>
          {state.selectedVariant?.title
            ? `Size (${getSizingUnit(cart)}): `
            : `Select size (${getSizingUnit(cart)})`}
          <Text sx={{ color: "grayscale.600" }}>
            {showInches && state.selectedVariant?.title
              ? sizeToInches(state.selectedVariant)
              : state.selectedVariant?.title}
          </Text>
        </Paragraph>
        <SizePickerList>
          {sortedVariants.map((v, i) => {
            const variant = state.product?.variants?.find((i) => i.id === v.id)

            let size = showInches ? sizeToInches(v) : v.title

            if (isDownCollection && isPillow(selectedColor)) {
              size = size + " " + selectedColor.type?.value
            }

            const titleLowerCase = v.title?.toLowerCase()

            return (
              <SizePickerListRow
                key={v.id}
                label={getPriceLabel(
                  variant,
                  cart?.region?.currency_code,
                  discountValue
                )}
                sideContent={
                  state?.selectedVariant?.id === v.id &&
                  !isVariantOutOfStock(variant) ? (
                    <NumberInput
                      value={state?.selectedVariant?.quantity || 1}
                      min={1}
                      max={state?.selectedVariant?.inventory_quantity}
                      onNumberChange={(value) => {
                        setState({
                          ...state,
                          selectedVariant: {
                            ...state.selectedVariant,
                            quantity: value,
                          },
                        })
                      }}
                      removeDisabled={state?.selectedVariant?.quantity === 1}
                      addDisabled={
                        state?.selectedVariant?.quantity ===
                        state?.selectedVariant?.inventory_quantity
                      }
                      sx={{ maxWidth: 22 }}
                    />
                  ) : v.sku?.startsWith("TT-") ? (
                    <Text sx={{ color: "grayscale.600" }}>{size}</Text>
                  ) : null
                }
                tagChildren={
                  isSale
                    ? ""
                    : isVariantLowInStock(variant)
                    ? `${variant.inventory_quantity} left in stock`
                    : isVariantOutOfStock(variant)
                    ? "Out of stock"
                    : ""
                }
                tagProps={{
                  sx: {
                    color: "primary",
                    display:
                      isVariantLowInStock(variant) ||
                      isVariantOutOfStock(variant)
                        ? "inline-block"
                        : "none",
                  },
                }}
                isDisabled={isVariantOutOfStock(variant)}
                isActive={state.selectedVariant?.id === v.id}
                onClickCapture={() => handleSizePickerClick(v)}
                sx={{
                  borderBlockEnd: variants?.length
                    ? i === variants.length - 1
                      ? 0
                      : "1px solid"
                    : "1px solid",
                }}
              >
                {v.sku?.startsWith("TT-")
                  ? selectedColor.type?.value
                  : (titleLowerCase === "270x235" ||
                      titleLowerCase === "193x203x40" ||
                      (titleLowerCase === "50x90" &&
                        selectedColor.title
                          ?.toLowerCase()
                          .includes("pillow"))) &&
                    cart?.region?.name === "United States"
                  ? "King"
                  : (titleLowerCase === "230x235" ||
                      titleLowerCase === "153x203x40" ||
                      (titleLowerCase === "50x70" &&
                        selectedColor.title
                          ?.toLowerCase()
                          .includes("pillow"))) &&
                    cart?.region?.name === "United States"
                  ? "Queen"
                  : titleLowerCase === "179x226" &&
                    cart?.region?.name === "United States"
                  ? "Twin / XL"
                  : size}
              </SizePickerListRow>
            )
          })}
        </SizePickerList>
        {(!isMobile || (isMobile && isVariantSelected)) && (
          <Box
            sx={{
              backgroundColor: "grayscale.white",
              position: "sticky",
              bottom: 0,
              paddingBlockEnd: 10,
              marginBlockEnd: -10,
            }}
          >
            <ProductButton
              sx={{ width: "100%", marginBlockStart: [0, 7] }}
              hasNotifyMe={hasNotifyMe}
              isInDrawer={true}
              isProductSoldOut={isProductSoldOut}
              isProductOutOfStock={isProductOutOfStock(state?.inventory)}
              isVariantSelected={isVariantSelected}
              isVariantOutOfStock={isVariantOutOfStock(state?.selectedVariant)}
              isVariantSoldOut={isVariantSoldOut}
              sizePickerVariant={sizePickerVariant}
              isGiftcard={isGiftcard}
              isMobile={isMobile}
              isProductRelated={false}
              isProductSingleSize={variants?.length < 2}
              onClick={() => handleProductButtonOnClick({ isInDrawer: true })}
              isVisuallyDisabled={createLineItem.isLoading}
              isLoading={createLineItem.isLoading}
            />
          </Box>
        )}
      </Drawer>
      <Drawer
        isOpened={openedDrawer === "size-picker-mobile"}
        position="bottom"
        onCloseClick={() => {
          handleSelectedVariantReset()
          setOpenedDrawer("")
          setLocked(false)
        }}
        padding="sm"
        sx={{ paddingBlockEnd: isZendeskOpen && 18 }}
      >
        <Box sx={{ marginBlockEnd: isZendeskOpen && -7 }}>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            {state.selectedVariant?.title
              ? `Size (${getSizingUnit(cart)}): `
              : `Select size (${getSizingUnit(cart)})`}
            {showInches && state.selectedVariant?.title
              ? sizeToInches(state.selectedVariant)
              : state.selectedVariant?.title}
          </Paragraph>
          {recommendedSizes.length > 0 && (
            <>
              <Paragraph sx={{ marginBlockStart: 10, marginBlockEnd: 5 }}>
                Recommended based on your region:
              </Paragraph>

              <SizePickerList>
                {recommendedSizes.map((v, i) => {
                  const variant = state.product?.variants?.find(
                    (i) => i.id === v.id
                  )

                  let size = showInches ? sizeToInches(v) : v.title
                  if (isDownCollection && isPillow(selectedColor)) {
                    size = size + " " + selectedColor.type?.value
                  }
                  const titleLowerCase = v.title?.toLowerCase()

                  return (
                    <SizePickerListRow
                      key={v.id}
                      label={getPriceLabel(
                        variant,
                        cart?.region?.currency_code,
                        discountValue
                      )}
                      sideContent={
                        state?.selectedVariant?.id === v.id &&
                        !isVariantOutOfStock(variant) && (
                          <NumberInput
                            value={state?.selectedVariant?.quantity || 1}
                            min={1}
                            max={state?.selectedVariant?.inventory_quantity}
                            onNumberChange={(value) => {
                              setState({
                                ...state,
                                selectedVariant: {
                                  ...state.selectedVariant,
                                  quantity: value,
                                },
                              })
                            }}
                            removeDisabled={
                              state?.selectedVariant?.quantity === 1
                            }
                            addDisabled={
                              state?.selectedVariant?.quantity ===
                              state?.selectedVariant?.inventory_quantity
                            }
                            sx={{ maxWidth: 22 }}
                          />
                        )
                      }
                      tagChildren={
                        isSale
                          ? ""
                          : isVariantLowInStock(variant)
                          ? `${variant.inventory_quantity} left in stock`
                          : isVariantOutOfStock(variant)
                          ? "Out of stock"
                          : ""
                      }
                      tagProps={{
                        sx: {
                          color: "primary",
                          display:
                            isVariantLowInStock(variant) ||
                            isVariantOutOfStock(variant)
                              ? "inline-block"
                              : "none",
                        },
                      }}
                      isDisabled={isVariantOutOfStock(variant)}
                      isActive={state.selectedVariant?.id === v.id}
                      onClickCapture={() => handleSizePickerClick(v)}
                      sx={{
                        borderBlockEnd: variants?.length
                          ? i === variants.length - 1
                            ? 0
                            : "1px solid"
                          : "1px solid",
                      }}
                    >
                      {v.sku?.startsWith("TT-")
                        ? selectedColor.type?.value
                        : (titleLowerCase === "270x235" ||
                            titleLowerCase === "193x203x40" ||
                            (titleLowerCase === "50x90" &&
                              selectedColor.title
                                ?.toLowerCase()
                                .includes("pillow"))) &&
                          cart?.region?.name === "United States"
                        ? "King"
                        : (titleLowerCase === "230x235" ||
                            titleLowerCase === "153x203x40" ||
                            (titleLowerCase === "50x70" &&
                              selectedColor.title
                                ?.toLowerCase()
                                .includes("pillow"))) &&
                          cart?.region?.name === "United States"
                        ? "Queen"
                        : titleLowerCase === "179x226" &&
                          cart?.region?.name === "United States"
                        ? "Twin / XL"
                        : size}
                    </SizePickerListRow>
                  )
                })}
              </SizePickerList>
            </>
          )}
          {recommendedSizes.length > 0 && (
            <Paragraph sx={{ marginBlockStart: 10, marginBlockEnd: 5 }}>
              Other sizes:
            </Paragraph>
          )}
          <SizePickerList>
            {sortedVariants
              .filter((i) => !recommendedSizes.includes(i))
              .map((v, i) => {
                const variant = state.product?.variants?.find(
                  (i) => i.id === v.id
                )

                let size = showInches ? sizeToInches(v) : v.title

                if (isDownCollection && isPillow(selectedColor)) {
                  size = size + " " + selectedColor.type?.value
                }

                return (
                  <SizePickerListRow
                    key={v.id}
                    label={getPriceLabel(
                      variant,
                      cart?.region?.currency_code,
                      discountValue
                    )}
                    sideContent={
                      state?.selectedVariant?.id === v.id &&
                      !isVariantOutOfStock(variant) ? (
                        <NumberInput
                          value={state?.selectedVariant?.quantity || 1}
                          min={1}
                          max={state?.selectedVariant?.inventory_quantity}
                          onNumberChange={(value) => {
                            setState({
                              ...state,
                              selectedVariant: {
                                ...state.selectedVariant,
                                quantity: value,
                              },
                            })
                          }}
                          removeDisabled={
                            state?.selectedVariant?.quantity === 1
                          }
                          addDisabled={
                            state?.selectedVariant?.quantity ===
                            state?.selectedVariant?.inventory_quantity
                          }
                          sx={{ maxWidth: 22 }}
                        />
                      ) : isTowel ? (
                        showInches ? (
                          sizeToInches(variant)
                        ) : (
                          variant?.title
                        )
                      ) : null
                    }
                    tagChildren={
                      isSale
                        ? ""
                        : isVariantLowInStock(variant)
                        ? `${variant.inventory_quantity} left in stock`
                        : isVariantOutOfStock(variant)
                        ? "Out of stock"
                        : ""
                    }
                    tagProps={{
                      sx: {
                        color: "primary",
                        display:
                          isVariantLowInStock(variant) ||
                          isVariantOutOfStock(variant)
                            ? "inline-block"
                            : "none",
                      },
                    }}
                    isDisabled={isVariantOutOfStock(variant)}
                    isActive={state.selectedVariant?.id === v.id}
                    onClickCapture={() => handleSizePickerClick(v)}
                    sx={{
                      borderBlockEnd: variants?.length
                        ? i === variants.length - 1
                          ? 0
                          : "1px solid"
                        : "1px solid",
                    }}
                  >
                    {isTowel
                      ? getToweLabel(v.title)
                      : v.title === "179x226" &&
                        cart?.region?.name === "United States"
                      ? "Twin / XL"
                      : size}
                  </SizePickerListRow>
                )
              })}
          </SizePickerList>
          {!isVariantSelected && (
            <Flex
              sx={{
                height: 29,
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingBlockEnd: 4,
              }}
            >
              <Flex sx={{ justifyContent: "space-between" }}>
                {sizeGuideGroups?.length && (
                  <Box>
                    <Link onClick={handleToggleSizeGuide}>Size guide</Link>
                  </Box>
                )}
                <Box>
                  <Link
                    onClick={() => {
                      setIsActivated(true)
                      setIsZendeskOpen(true)
                    }}
                  >
                    Need assistance?
                  </Link>
                </Box>
              </Flex>
            </Flex>
          )}
          {(!isMobile || (isMobile && isVariantSelected)) && (
            <Box
              sx={{
                height: 29,
                position: "sticky",
                bottom: isZendeskOpen ? 0 : 10,
                backgroundColor: "grayscale.white",
                "::after": {
                  content: '""',
                  display: "block",
                  width: "100%",
                  height: 10,
                  backgroundColor: "grayscale.white",
                  position: "fixed",
                  left: 0,
                  bottom: 0,
                },
              }}
            >
              <ProductButton
                sx={{ width: "100%", marginBlockStart: 8 }}
                hasNotifyMe={hasNotifyMe}
                isInDrawer={true}
                isProductSoldOut={isProductSoldOut}
                isProductOutOfStock={isProductOutOfStock(state?.inventory)}
                isVariantSelected={isVariantSelected}
                isVariantOutOfStock={isVariantOutOfStock(
                  state?.selectedVariant
                )}
                isVariantSoldOut={isVariantSoldOut}
                sizePickerVariant={sizePickerVariant}
                isGiftcard={isGiftcard}
                isMobile={isMobile}
                isProductRelated={false}
                isProductSingleSize={variants?.length < 2}
                onClick={() => handleProductButtonOnClick({ isInDrawer: true })}
                isVisuallyDisabled={createLineItem.isLoading}
                isLoading={createLineItem.isLoading}
              />
            </Box>
          )}
        </Box>
      </Drawer>
      {isActivated && <ZendeskWidget />}
      {sizeGuideDrawer}
    </Layout>
  )
}

export default Product
