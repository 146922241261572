const productsWithCm = [
  "TT-",
  "BM-",
  "LC-",
  "PNW-",
  "BB-BS",
  "FB-",
  "CA-",
  "MP-",
  "FP-",
  "NA-",
  "GT-",
  "TC-",
  "KT-",
  "PM-",
  "DN-",
  "BS-",
]

export const hasMeasureUnit = (sku: string): boolean => {
  return productsWithCm.some((cmSKU) => sku.startsWith(cmSKU))
}
