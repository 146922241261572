import { ProductVariant } from "@medusajs/medusa"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { ContentfulVariant } from "gatsby-node"

export type GetSizePickerVariant =
  | "single"
  | "named-list"
  | "named-inline-list"
  | "inline-list"
  | "wrapped-inline-list"
  | "list"

export const getSizePickerVariant = (
  variants: ProductVariant[] | ContentfulVariant[] | PricedVariant[],
  isGiftcard?: boolean
): string => {
  if (!variants?.length || !variants?.[0]?.sku) {
    return ""
  }

  if (variants.length < 2) {
    return "single"
  }

  if (variants[0]?.sku.startsWith("TT-")) {
    return "named-list"
  }

  if (
    variants[0]?.sku.startsWith("BTK-") ||
    variants[0]?.sku.startsWith("SWSET-") ||
    variants[0]?.sku.startsWith("DN-BCBL-") ||
    variants[0]?.sku.startsWith("DN-BCHS-")
  ) {
    return "named-inline-list"
  }

  if (
    variants[0]?.sku.startsWith("SW") ||
    variants[0]?.sku.startsWith("BT-") ||
    variants[0]?.sku.startsWith("BH-") ||
    variants[0]?.sku.startsWith("CB-") ||
    variants[0]?.sku.startsWith("TSH-") ||
    variants[0]?.sku.startsWith("TS-") ||
    variants[0]?.sku.startsWith("KAF-") ||
    variants[0]?.sku.startsWith("LTSH-") ||
    isGiftcard
  ) {
    return "inline-list"
  }

  if (
    variants?.[0]?.sku?.startsWith("UJ-") ||
    variants?.[0]?.sku?.startsWith("NA-") ||
    variants?.[0]?.sku?.startsWith("SS-BLA-") ||
    variants?.[0]?.sku?.startsWith("SS-IVO-")
  ) {
    return "wrapped-inline-list"
  }

  return "list"
}
