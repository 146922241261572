import * as React from "react"
import {
  Box,
  BoxProps,
  ParagraphProps,
  Text,
  ThemeUIStyleObject,
} from "theme-ui"

import { Tag } from "./Tag"

const SizePickerListColumn: React.FC<
  React.ComponentPropsWithoutRef<"td"> & BoxProps & { hasBorder?: boolean }
> = ({ hasBorder, children, sx, ...rest }) => (
  <Box
    as="td"
    sx={{
      height: 13,
      border: "none",
      borderBlockEnd: hasBorder && "1px solid",
      borderBlockEndColor: hasBorder && "grayscale.200",
      cursor: "pointer",
      paddingInline: 2,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

interface SizePickerListRowProps {
  label?: React.ReactNode
  sideContent?: React.ReactNode
  tagProps?: ParagraphProps
  tagChildren?: React.ReactNode
  hasBorder?: boolean
  isDisabled?: boolean
  isActive?: boolean
  isViewAll?: boolean
  valueSx?: ThemeUIStyleObject
}

export const SizePickerListRow: React.FC<BoxProps & SizePickerListRowProps> = ({
  children,
  label,
  sideContent,
  tagProps,
  tagChildren,
  hasBorder = true,
  isDisabled,
  isActive,
  isViewAll,
  valueSx,
  sx,
  ...rest
}) => (
  <Box
    as="tr"
    sx={{ color: isDisabled ? "grayscale.500" : "primary", ...sx }}
    {...rest}
  >
    <SizePickerListColumn
      hasBorder={hasBorder}
      colSpan={isViewAll && 3}
      sx={{ width: "1px" }}
    >
      <Box sx={{ ...valueSx }}>
        <Text
          sx={{
            whiteSpace: "nowrap",
            borderBlockEnd: "1px solid",
            borderBlockEndColor: isActive ? "primary" : "transparent",
            textDecoration: isDisabled && "line-through",
            transition: "border-block-end-color 0.2s",

            "tr:hover &": {
              borderBlockEndColor: !isDisabled && [null, "primary"],
            },
          }}
        >
          {children}
        </Text>
      </Box>
    </SizePickerListColumn>
    {!isViewAll && (
      <SizePickerListColumn hasBorder={hasBorder} sx={{ width: "1px" }}>
        {label && (
          <Text
            sx={{
              color: isDisabled
                ? "grayscale.500"
                : !isActive
                ? "primary"
                : null,
              whiteSpace: "nowrap",
              transition: "color .2s",
            }}
          >
            {label}
          </Text>
        )}
      </SizePickerListColumn>
    )}
    {!isViewAll && (
      <SizePickerListColumn hasBorder={hasBorder}>
        {tagChildren && <Tag {...tagProps}>{tagChildren}</Tag>}
      </SizePickerListColumn>
    )}
    <SizePickerListColumn
      hasBorder={hasBorder}
      sx={{ textAlign: "right", width: 26 }}
    >
      {sideContent && <Box sx={{ display: "inline-block" }}>{sideContent}</Box>}
    </SizePickerListColumn>
  </Box>
)

export const SizePickerList: React.FC<
  BoxProps & React.ComponentPropsWithoutRef<"table">
> = ({ children, ...rest }) => (
  <Box as="table" {...rest}>
    {children}
  </Box>
)
